import { Col, Row } from 'antd';
import { FieldInputSelect } from 'components/atoms/FieldInputSelect';
import { FieldInputTextArea } from 'components/atoms/FieldInputTextArea';
import { RepairStageSelect } from 'components/atoms/RepairStageSelect';
import { ResolutionCodeSelect } from 'components/atoms/ResolutionCodeSelect';
import React, { FC } from 'react';

type Props = {
  repairTypeId?: string;
};

const LineItemTechFormComponent: FC<Props> = ({ repairTypeId }) => {
  return (
    <Row gutter={[20, 10]}>
      <Col span={12}>
        <RepairStageSelect />
      </Col>
      <Col span={12}>
        <FieldInputSelect fieldName="faultCode" label="Fault Code" options={[]} placeholder="Fault Code" style={{ width: '100%' }} />
      </Col>
      <Col span={12}>
        <ResolutionCodeSelect isStage />
      </Col>
      <Col span={24}>
        <FieldInputTextArea style={{ height: 100 }} fieldName="notes" label="Notes" placeholder="Notes" />
      </Col>
    </Row>
  );
};

export const LineItemTechForm = React.memo(LineItemTechFormComponent);
