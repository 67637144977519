import { User } from '@auth0/auth0-spa-js';

export const deleteRepairLine = async ({ recId, user, dataAreaId }: { recId: number; user: User; dataAreaId: string }): Promise<string> => {
  const resp = await fetch('https://mdsi-noodle-djdchdane8hwfxgc.eastus2-01.azurewebsites.net/api', {
    method: 'POST',
    headers: {
      'Content-Type': 'text/xml',
      SOAPAction: 'http://tempuri.org/IRepairModule/DeleteRepairLine'
    },
    body: `
    <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
      <s:Body>
        <DeleteRepairLine xmlns="http://tempuri.org/">
          <repairLineRecId>${recId}</repairLineRecId>
            <user xmlns:d4p1="http://schemas.datacontract.org/2004/07/WarehouseManagement.Model.Repair" xmlns:i="http://www.w3.org/2001/XMLSchema-instance">
              <d4p1:UserName>${user.name}</d4p1:UserName>
            </user>
          <dataAreaId>${dataAreaId}</dataAreaId>
    </DeleteRepairLine>
  </s:Body>
</s:Envelope>`
  });

  const textResponse = await resp.text();

  return textResponse;
  //   const parsedResp = parseSoapResponse(textResponse, table ?? '');
};
