import { EditOutlined } from '@ant-design/icons';
import { Button, Modal, Space, Typography } from 'antd';
import { colors, toRgba } from 'common/styles/colors';
import { ColorCard } from 'components/molecules/ColorCard';
import { RepairOrderToBePicked } from 'components/molecules/LineItemTechQueueTable';
import { LineItemTechForm } from 'components/organisms/LineItemTechForm';
import { FormikProvider, useFormik } from 'formik';
import React, { FC, useState } from 'react';
import { CompleteItemModal } from './CompleteItemModal';

type Props = {
  repairTypeId?: string;
  isEdit?: boolean;
  record?: Partial<RepairOrderToBePicked>;
  idx?: number;
};

export const LineItemTechQueueComponent: FC<Props> = ({ repairTypeId, isEdit = false, record, idx }) => {
  const [visible, setVisible] = useState(false);
  const [tabKey, setTabKey] = useState<string>('1');
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [formVals, setFormVals] = useState<any>({
    unitPrice: '0.00',
    sla: null
  });

  const formik = useFormik<any>({
    enableReinitialize: true,
    initialValues: {
      itemId: isEdit ? record?.itemId ?? '' : ''
    },
    onSubmit: async (values) => console.log(values)
  });

  const handleCancel = (): void => {
    formik.resetForm();
    setVisible(false);
  };
  const handleSubmit = ({ callback }: { callback?: () => void }): void => {
    formik.submitForm();
  };

  return (
    <>
      <Button size="small" icon={<EditOutlined />} onClick={() => setVisible(true)} />
      <Modal
        footer={(originNode) => {
          return (
            <Space>
              {originNode}
              <CompleteItemModal />
            </Space>
          );
        }}
        okText={'Save'}
        destroyOnClose
        afterClose={() => {
          setTabKey('1');
          setSelectedRows([]);
          setFormVals({ unitPrice: '0.00', sla: 0 });
        }}
        width={825}
        closable={false}
        open={visible}
        onCancel={handleCancel}
        onOk={() => handleSubmit({})}>
        <ColorCard color={toRgba(colors.orangeWeb, 0.4)} title={'Update Line'}>
          <FormikProvider value={formik}>
            <Space size={12} style={{ width: '100%', marginBottom: 16 }}>
              <Typography.Text style={{ fontSize: 13, color: 'rgb(47, 85, 150)' }}>
                Repair Order Id: <Typography.Text style={{ fontSize: 13 }}>{record?.repairOrderId}</Typography.Text>
              </Typography.Text>
              <Typography.Text style={{ fontSize: 13, color: 'rgb(47, 85, 150)' }}>
                Item Id: <Typography.Text style={{ fontSize: 13 }}>{record?.itemId}</Typography.Text>
              </Typography.Text>
              <Typography.Text style={{ fontSize: 13, color: 'rgb(47, 85, 150)' }}>
                Serial Number: <Typography.Text style={{ fontSize: 13 }}>{record?.repairSerialNumber}</Typography.Text>
              </Typography.Text>
              <Typography.Text style={{ fontSize: 13, color: 'rgb(47, 85, 150)' }}>
                Technician: <Typography.Text style={{ fontSize: 13 }}>{record?.repairTechnicianId || 'N/A'}</Typography.Text>
              </Typography.Text>
            </Space>
            <LineItemTechForm />
          </FormikProvider>
        </ColorCard>
      </Modal>
    </>
  );
};

export const LineItemTechQueueModal = React.memo(LineItemTechQueueComponent);
