import { CheckCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Space, Typography } from 'antd';
import { colors, toRgba } from 'common/styles/colors';
import { ColorCard } from 'components/molecules/ColorCard';
import { FC, useState } from 'react';
import { useAppSelector } from 'redux/store';
import { ItemStatusSelect } from './ItemStatusSelect';
import { RepairTechSelect } from './RepairTechSelect';

export const CompleteItemModal: FC = () => {
  const [visible, setVisible] = useState(false);
  const { selectedDataAreaId } = useAppSelector((state) => state.app);
  const [form, setForm] = useState<{
    repairStageId: string;
    technicianId: string;
  }>({
    repairStageId: '',
    technicianId: ''
  });

  const handleSubmit = async (): Promise<void> => {
    try {
      console.log(form);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Button style={{ background: 'green', color: 'white' }} onClick={() => setVisible(true)}>
        Complete Item <CheckCircleOutlined />
      </Button>

      <Modal onOk={handleSubmit} width={425} closable={false} destroyOnClose okText="Submit Item" open={visible} onCancel={() => setVisible(false)}>
        <ColorCard color={toRgba(colors.illuminatingEmerald, 0.4)} title={'Complete Item'}>
          <Space direction="vertical" size={16} style={{ width: '100%' }}>
            <div>
              <Typography.Text>Item Status</Typography.Text>
              <ItemStatusSelect isSubmit repaidTypeId={form.repairStageId} />
            </div>
            <div>
              <Typography.Text>Bin Location</Typography.Text>
              <RepairTechSelect setForm={setForm} isTech />
            </div>
          </Space>
        </ColorCard>
      </Modal>
    </>
  );
};
