import { Col, Row } from 'antd';
import { colors, toRgba } from 'common/styles/colors';
import { AdminSelect } from 'components/atoms/AdminSelect';
import { CustomerSelect } from 'components/atoms/CustomerSelect';
import { FieldInput } from 'components/atoms/FieldInput';
import { FieldInputDatePicker } from 'components/atoms/FieldInputDatePicker';
import { RepairLabSelect } from 'components/atoms/RepairLabSelect';
import { RepairTypeSelect } from 'components/atoms/RepairTypeSelect';
import { ColorCard } from 'components/molecules/ColorCard';

export const RepairForm = (): JSX.Element => {
  return (
    <ColorCard color={toRgba(colors.illuminatingEmerald, 0.4)} title={'General Information'}>
      <Row gutter={[20, 0]}>
        <Col span={12}>
          <CustomerSelect />
        </Col>
        <Col span={12}>
          <RepairLabSelect />
        </Col>
        <Col span={12}>
          <RepairTypeSelect />
        </Col>
        <Col span={12}>
          <AdminSelect />
        </Col>
        <Col span={12}>
          <FieldInputDatePicker fieldName="OrderDate" label="Order Date" placeholder="Order Date" />
        </Col>
        <Col span={12}>
          <FieldInput fieldName="CustomerReference" label="Reference Number" placeholder="Reference Number" />
        </Col>
      </Row>
    </ColorCard>
  );
};
