import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Col, Row, Space, Typography } from 'antd';
import { MoveToProcessLines } from 'components/atoms/MoveToProcessLines';
import { LineItemTechQueueTable, RepairOrderToBePicked } from 'components/molecules/LineItemTechQueueTable';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

export const TechQ = (): JSX.Element => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState<RepairOrderToBePicked[]>([]);

  const saveButton = useRef<string>('');

  const dispatch = useDispatch();
  const nav = useNavigate();

  const saveAndExit = async (): Promise<void> => {
    saveButton.current = 'exit';
    // await formik.submitForm();
  };
  const binaryToString = (e: string): string => {
    return Uint8Array.from(e.split(' ').map((r) => parseInt(r, 2))).reduce((acc, cur) => acc + String.fromCharCode(cur), '');
  };

  console.log(
    binaryToString(
      '01011010 01000100 01000110 01110100 01001101 00110010 00110100 01101011 01100001 01010111 00111001 01110101 01011000 01101010 01000101 01100111 01011001 01111010 01000010 01110100 01010000 00110011 01010110 01010101 01001101 01010111 00110101 01101110'
    )
  );

  return (
    <>
      <Row justify={'space-between'} align={'middle'} style={{ marginBottom: 12, width: '100%' }}>
        <Typography.Title style={{ margin: 0, padding: 0 }} level={4}>
          Tech Queue
        </Typography.Title>
        <Space>
          <Button onClick={() => nav('/')} icon={<ArrowLeftOutlined />}>
            Back
          </Button>
          <MoveToProcessLines selectedRows={selectedRows} setSelectedRows={setSelectedRows} />
        </Space>
      </Row>

      <Row gutter={[20, 0]}>
        <Col span={24}>
          <LineItemTechQueueTable setSelectedRows={setSelectedRows} />
        </Col>
      </Row>
    </>
  );
};
