import { EditOutlined, PlusCircleOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, InputNumber, message, Modal, Space, Tabs } from 'antd';
import { colors, toRgba } from 'common/styles/colors';
import { ColorCard } from 'components/molecules/ColorCard';
import { POReceiptLine } from 'components/molecules/LineItemTable';
import { LineItemForm } from 'components/organisms/LineItemForm';
import { LineItemSearch } from 'components/organisms/LineItemSearch';
import { RepairOrderPayload } from 'components/organisms/RepairsTable';
import { FormikProvider, useFormik, useFormikContext } from 'formik';
import { Tab } from 'rc-tabs/lib/interface';
import React, { FC, useState } from 'react';
import { UnitPriceInput } from './UnitPriceInput';

type Props = {
  repairTypeId?: string;
  isEdit?: boolean;
  record?: POReceiptLine;
  idx?: number;
};

export const LineItemModalComponent: FC<Props> = ({ repairTypeId, isEdit = false, record, idx }) => {
  const [visible, setVisible] = useState(false);
  const { setValues } = useFormikContext<RepairOrderPayload>();
  const [tabKey, setTabKey] = useState<string>('1');
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [formVals, setFormVals] = useState<any>({
    unitPrice: '0.00',
    sla: null
  });

  const formik = useFormik<POReceiptLine>({
    enableReinitialize: true,
    initialValues: {
      itemId: isEdit ? record?.itemId ?? '' : '',
      itemStatus: isEdit ? record?.itemStatus ?? '' : '',
      serialNumber: isEdit ? record?.serialNumber ?? '' : '',
      status: isEdit ? record?.status ?? 'New' : 'New',
      technician: isEdit ? record?.technician ?? '' : '',
      unitPrice: isEdit ? Number(record?.unitPrice).toFixed(2) ?? '0.00' : '0.00',
      sla: isEdit ? record?.sla ?? 0 : 0,
      salesOrder: isEdit ? record?.salesOrder ?? '' : '',
      recId: isEdit ? record?.recId ?? 0 : 0,
      repairOrderId: isEdit ? record?.repairOrderId ?? '' : '',
      dateStaged: isEdit ? record?.dateStaged ?? '' : ''
    },
    onSubmit: async (values) => {
      const price = Number(values.unitPrice);

      if (values.itemId === '') {
        message.error('Please select an item');

        return;
      }

      setValues((prev) => {
        if (isEdit) {
          const updatedLines = prev.Lines.map((line, index) => {
            if (idx === index) {
              return { ...line, ...(values as POReceiptLine) };
            }

            return line;
          });

          return { ...prev, Lines: updatedLines };
        }

        return { ...prev, Lines: [...(prev.Lines ?? []), { ...values, unitPrice: price }] };
      });

      formik.resetForm();
      setVisible(false);
    }
  });

  const handleCancel = (): void => {
    formik.resetForm();
    setVisible(false);
  };
  const handleSubmit = ({ callback }: { callback?: () => void }): void => {
    if (tabKey === '2') {
      const formattedRows = selectedRows.map((row) => ({
        itemId: row.itemId,
        serialNumber: row.inventSerialId,
        unitPrice: formVals.unitPrice || '0.00',
        sla: formVals.sla || 0,
        recId: 0,
        dateStaged: undefined,
        itemStatus: '',
        status: 'New',
        technician: '',
        salesOrder: ''
      }));

      setValues((prev) => ({ ...prev, Lines: [...(prev.Lines ?? []), ...(formattedRows as POReceiptLine[])] }));
      setVisible(false);

      return;
    }
    formik.submitForm();
  };

  const items: Tab[] = [
    {
      key: '1',
      label: 'Line Item',
      children: <LineItemForm repairTypeId={repairTypeId} />,
      icon: <PlusOutlined />
    },
    {
      key: '2',
      label: 'Search',
      children: <LineItemSearch setSelectedRows={setSelectedRows} handleSubmit={handleSubmit} />,
      icon: <SearchOutlined />,
      disabled: isEdit
    }
  ];

  return (
    <>
      {!isEdit && (
        <Button icon={<PlusCircleOutlined />} onClick={() => setVisible(true)}>
          Add Line Item
        </Button>
      )}
      {isEdit && <Button size="small" icon={<EditOutlined />} onClick={() => setVisible(true)} />}
      <Modal
        footer={(originNode) => {
          if (tabKey === '1') {
            return originNode;
          }

          return (
            <Space>
              <UnitPriceInput setFormVals={setFormVals} unitPrice={formVals.unitPrice} />
              <InputNumber
                addonAfter="SLA (Days)"
                controls={false}
                defaultValue={null}
                style={{ width: 150 }}
                value={formVals.sla}
                onChange={(e) => setFormVals((prev: any) => ({ ...prev, sla: e }))}
                aria-controls="sla"
              />
              {originNode}
            </Space>
          );
        }}
        okText={isEdit ? 'Save' : 'Add'}
        destroyOnClose
        afterClose={() => {
          setTabKey('1');
          setSelectedRows([]);
          setFormVals({ unitPrice: '0.00', sla: 0 });
        }}
        width={1000}
        closable={false}
        open={visible}
        onCancel={handleCancel}
        onOk={() => handleSubmit({})}>
        <ColorCard color={toRgba(colors.orangeWeb, 0.4)} title={'Line Items'}>
          <FormikProvider value={formik}>
            <Tabs
              defaultActiveKey={tabKey}
              onChange={(key) => setTabKey(key)}
              activeKey={tabKey}
              destroyInactiveTabPane
              tabBarStyle={{ marginBottom: 25 }}
              style={{ margin: 0, padding: 0 }}
              type="line"
              size="middle"
              items={items}
            />
          </FormikProvider>
        </ColorCard>
      </Modal>
    </>
  );
};

export const LineItemModal = React.memo(LineItemModalComponent);
