import { useAuth0 } from '@auth0/auth0-react';
import { getDataAreaIds } from 'api/GetDataAreaIds';
import { getLookupTables } from 'api/GetLookupTables';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setAccessToken, setDataAreaIds, setLookupTables, setPreviousDataAreaId, setUser } from 'redux/slices/appSlice';
import { useAppSelector } from 'redux/store';

export const useAuthentication = (): { isLoading: boolean; cookieNotFound: boolean } => {
  const { accessToken } = useAppSelector((state) => state.app);
  const dispatch = useDispatch();
  const { isAuthenticated, loginWithRedirect, isLoading, user, getAccessTokenSilently } = useAuth0();
  const [cookieNotFound, setCookieNotFound] = useState(false);

  // Check if User is logged in, if not, redirect them to login screen
  useEffect(() => {
    const fetchLookipTables = async (): Promise<void> => {
      const data = await getLookupTables();
      const dataAreaIds = await getDataAreaIds();

      dispatch(setDataAreaIds(dataAreaIds));

      dispatch(setLookupTables(data.textResponse));
      dispatch(setPreviousDataAreaId(data.dataAreaId));
    };

    fetchLookipTables();
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect({ appState: { targetUrl: '/' } });
    }
    if (isAuthenticated && accessToken === null) {
      getAccessTokenSilently().then((accessToken) => dispatch(setAccessToken(accessToken)));
    }
  }, [accessToken, dispatch, getAccessTokenSilently, isAuthenticated, isLoading, loginWithRedirect]);

  useEffect(() => {
    if (user) {
      dispatch(setUser(user));
    }
  }, [dispatch, user]);

  return { isLoading, cookieNotFound };
};
