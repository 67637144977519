import { RepairOrderToBePicked } from 'components/molecules/LineItemTechQueueTable';
import { formatDate } from './CreateRepairOrder';

export const markRepairLinesPicked = async ({ line, dataAreaId, technicianId }: { line: RepairOrderToBePicked; dataAreaId: string; technicianId: string }): Promise<string> => {
  const payload = `
        <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
          <s:Body>
            <MarkLineInProcess xmlns="http://tempuri.org/">
                <repairLine xmlns:d4p1="http://schemas.datacontract.org/2004/07/WarehouseManagement.Model.Repair" xmlns:i="http://www.w3.org/2001/XMLSchema-instance">
                <d4p1:RepairLabId>${line.repairLabId || ''}</d4p1:RepairLabId>
                    <d4p1:RecId>${line.recId || 0}</d4p1:RecId>
                    <d4p1:InventLocationId>${line.inventLocationId || ''}</d4p1:InventLocationId>
                    <d4p1:WMSLocationId>${line.wMSLocationId || ''}</d4p1:WMSLocationId>
                    <d4p1:RepairSerialNumber>${line.repairSerialNumber || ''}</d4p1:RepairSerialNumber>
                    <d4p1:ConfigId>${line.configId || ''}</d4p1:ConfigId>
                    <d4p1:InventSizeId>${line.inventSizeId || ''}</d4p1:InventSizeId>
                    <d4p1:ItemId>${line.itemId || ''}</d4p1:ItemId>
                    <d4p1:SalesOrderId>SO123456789</d4p1:SalesOrderId>
                    <d4p1:RepairStageId>${line.repairStageId || ''}</d4p1:RepairStageId>
                    <d4p1:RepairTechnicianId>${technicianId}</d4p1:RepairTechnicianId>
                    <d4p1:HoursWorked>2</d4p1:HoursWorked>
                    <d4p1:SLABusinessDays>${Number(line.sLABusinessDays) || 10}</d4p1:SLABusinessDays>
                    ${line.dateStaged ? `<d4p1:DateStaged>${formatDate(line.dateStaged).toISOString()}</d4p1:DateStaged>` : ''}
                    <d4p1:ItemStatusId>DEFECTIVE</d4p1:ItemStatusId>
                    <d4p1:InboundJournalId></d4p1:InboundJournalId>
                    <d4p1:InventColorId>${line.inventColorId || ''}</d4p1:InventColorId>
                    <d4p1:WmsLocationIdInProcess>${line.wmsLocationIdInProcess || ''}</d4p1:WmsLocationIdInProcess>
                    <d4p1:InventLocationIdInProcess>${line.inventLocationIdInProcess || ''}</d4p1:InventLocationIdInProcess>
                    <d4p1:WmsLocationIdStaging>${line.wmsLocationIdStaging || ''}</d4p1:WmsLocationIdStaging>
                    <d4p1:RepairOrderId>${line.repairOrderId || ''}</d4p1:RepairOrderId>
                    <d4p1:InventLocationIdStaging>${line.inventLocationIdStaging || ''}</d4p1:InventLocationIdStaging>
                    <d4p1:InventLocationIdFinished>${line.inventLocationIdFinished || ''}</d4p1:InventLocationIdFinished>
                    <d4p1:WmsLocationIdFinished>${line.wmsLocationIdFinished || ''}</d4p1:WmsLocationIdFinished>
                    <d4p1:CustomerAccountNum>${line.customerAccountNum || ''}</d4p1:CustomerAccountNum>
                    <d4p1:OrderDate>${line.orderDate ? formatDate(line.orderDate).toISOString() : ''}</d4p1:OrderDate>
                    <d4p1:RepairLabInventLocationId>${line.repairLabInventLocationId || ''}</d4p1:RepairLabInventLocationId>
                    <d4p1:RepairType>${line.repairType || ''}</d4p1:RepairType>
                    <d4p1:RepairPrice>${Number(line.repairPrice) || 1.0}</d4p1:RepairPrice>
                </repairLine>
                <user xmlns:d4p1="http://schemas.datacontract.org/2004/07/WarehouseManagement.Model.Repair" xmlns:i="http://www.w3.org/2001/XMLSchema-instance">
                <d4p1:UserName>Carson Coyle</d4p1:UserName>
                </user>
                <dataAreaId>${dataAreaId}</dataAreaId>
            </MarkLineInProcess>
         </s:Body>
        </s:Envelope>`;

  console.log(payload);
  const resp = await fetch('https://mdsi-noodle-djdchdane8hwfxgc.eastus2-01.azurewebsites.net/api', {
    method: 'POST',
    headers: {
      'Content-Type': 'text/xml',
      SOAPAction: 'http://tempuri.org/IRepairModule/MarkLineInProcess'
    },
    body: payload
  });

  const textResponse = await resp.text();

  return textResponse;
};
