/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Input, InputRef, Row, Space, Spin, Table, TableColumnType } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { FilterDropdownProps } from 'antd/es/table/interface';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { LoaderWithMessage } from 'components/common/LoaderWithMessage';
import { POReceiptLine } from 'components/molecules/LineItemTable';
import { FancyInput } from 'components/ui/filters/FancyInput';
import { FancySelect } from 'components/ui/filters/FancySelect';
import { Shipment } from 'models/DockReceipt';
import moment from 'moment';
import { FC, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSelectedRepair } from 'redux/slices/appSlice';
import { useAppSelector } from 'redux/store';
import xml2js from 'xml2js';

export type RepairOrder = {
  repairOrderId: string;
  customerId: string;
  orderDate: string;
  status: string;
  repairType: string;
  repairLab: string;
  lineItems?: POReceiptLine[];
  customerReference: string;
  repairAdmin: string;
  recId: number;
};

export type RepairOrderPayload = {
  RecId: number;
  RepairLabId: string;
  RepairStatusId: string;
  RepairTypeId: string;
  RepairAdmin: string;
  CustomerReference: string;
  RMANumber: string;
  OrderDate: string;
  CustomerAccountNumber: string;
  RepairOrderId: string;
  Lines: POReceiptLine[];
};

export const RepairsTable: FC = () => {
  const { xs } = useBreakpoint();
  const dispatch = useDispatch();
  const [shipment, setShipment] = useState<Shipment | undefined>(undefined);
  const [repairData, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const { selectedDataAreaId, previousDataAreaId } = useAppSelector((state) => state.app);
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const [filterVals, setFilterVals] = useState({
    repairOrderId: '',
    customerId: '',
    repairType: '',
    repairLab: '',
    status: ''
  });
  const nav = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const url = 'https://mdsi-noodle-djdchdane8hwfxgc.eastus2-01.azurewebsites.net/api';

      setLoading(true);

      const resp = await fetch(url, {
        method: 'POST',
        cache: 'force-cache',
        headers: {
          'Content-Type': 'text/xml',
          SOAPAction: 'http://tempuri.org/IRepairModule/GetAllOpenRepairOrdersNoLineItems'
        },
        body: `
                <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:tns="http://tempuri.org/">
    <soapenv:Header/>
    <soapenv:Body>
      <tns:GetAllOpenRepairOrdersNoLineItems>
        <tns:dataAreaId>${selectedDataAreaId}</tns:dataAreaId>
      </tns:GetAllOpenRepairOrdersNoLineItems>
    </soapenv:Body>
  </soapenv:Envelope>
              `
      });

      const textResponse = await resp.text();

      const xmlParser = new xml2js.Parser();

      const parsedString = textResponse.replace('\ufeff', '');

      xmlParser.parseStringPromise(parsedString).then((result) => {
        const rawData = result[`s:Envelope`]?.[`s:Body`][0]?.GetAllOpenRepairOrdersNoLineItemsResponse[0].GetAllOpenRepairOrdersNoLineItemsResult[0]?.[`a:RepairOrder`];

        if (!rawData) return setData([]);
        const formattedData = rawData.map((item: any) => {
          return {
            repairOrderId: item[`a:RepairOrderId`][0],
            customerId: item[`a:CustomerAccountNumber`][0],
            orderDate: item[`a:OrderDate`][0],
            status: item[`a:RepairStatusId`][0],
            repairType: item[`a:RepairTypeId`][0],
            repairLab: item[`a:RepairLabId`][0],
            repairAdmin: item[`a:RepairAdmin`][0],
            customerReference: item[`a:CustomerReference`][0],
            recId: item[`a:RecId`][0],
            lineItems: []
          };
        });

        setData(formattedData);
      });

      setLoading(false);
    };

    fetchData();
  }, [selectedDataAreaId]);

  const filteredData = repairData?.filter((item) => {
    return (
      item?.repairOrderId?.toLowerCase().includes(filterVals.repairOrderId?.toLowerCase()) &&
      item?.customerId?.toLowerCase().includes(filterVals.customerId?.toLowerCase()) &&
      item?.repairType?.toLowerCase().includes(filterVals.repairType?.toLowerCase()) &&
      item?.repairLab?.toLowerCase().includes(filterVals.repairLab?.toLowerCase()) &&
      item?.status?.toLowerCase().includes(filterVals.status?.toLowerCase())
    );
  });

  const handleSearch = (selectedKeys: string[], confirm: FilterDropdownProps['confirm'], dataIndex: string) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: string): TableColumnType<RepairOrder> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button type="primary" onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}>
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}>
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
    onFilter: (value, record) => {
      const recordValue = record[dataIndex as keyof RepairOrder];

      return recordValue
        ? recordValue
            .toString()
            .toLowerCase()
            .includes((value as string).toLowerCase())
        : false;
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? text : text)
  });

  const columns: ColumnsType<RepairOrder> = [
    {
      title: 'Repair Order ID',
      dataIndex: 'repairOrderId',
      key: 'repairOrderId',

      ...getColumnSearchProps('repairOrderId'),
      render: (text: string, record) => (
        <Button
          style={{ margin: 0, padding: 0, height: 15 }}
          onClick={() => {
            nav(`edit/${text}`);
            dispatch(setSelectedRepair(record));
          }}
          type="link">
          {text}
        </Button>
      )
    },
    {
      title: 'Customer ID',
      dataIndex: 'customerId',
      key: 'customerId',
      ...getColumnSearchProps('customerId')
    },
    {
      title: 'Order Date',
      dataIndex: 'orderDate',
      key: 'orderDate',
      render: (text: string) => moment(text).format('MM/DD/YYYY')
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status'
    },
    {
      title: 'Repair Type',
      dataIndex: 'repairType',
      key: 'repairType'
    },
    {
      title: 'Repair Lab',
      dataIndex: 'repairLab',
      key: 'repairLab'
    }
  ];

  return (
    <Space direction="vertical" size={10} style={{ width: '100%' }}>
      <Spin spinning={isRefreshing} indicator={<LoaderWithMessage loadingMessage="Refreshing Data Tables..." />}>
        <Row style={{ background: 'white', padding: 16, margin: 0 }} gutter={xs ? [0, 25] : [8, 8]}>
          <Col xs={24} sm={8} md={6} lg={4} xl={4} xxl={4}>
            <FancyInput
              onChange={(e) => {
                if (!e) return setFilterVals((prev) => ({ ...prev, repairOrderId: '' }));
                setFilterVals((prev) => ({ ...prev, repairOrderId: e.target.value }));
              }}
              placeholder="Repair Order ID"
            />
          </Col>
          <Col xs={24} sm={8} md={6} lg={4} xl={4} xxl={4}>
            <FancySelect
              options={['Open', 'Completed', 'Cancelled'].map((opt) => ({ label: opt, value: opt }))}
              onChange={(e) => {
                if (!e) return setFilterVals((prev) => ({ ...prev, status: '' }));
                setFilterVals((prev) => ({ ...prev, status: e }));
              }}
              placeholder="Status"
              value={filterVals.status}
            />
          </Col>
          <Col xs={24} sm={8} md={6} lg={4} xl={4} xxl={4}>
            <FancyInput
              onChange={(e) => {
                if (!e) return setFilterVals((prev) => ({ ...prev, customerId: '' }));
                setFilterVals((prev) => ({ ...prev, customerId: e.target.value }));
              }}
              placeholder="Customer ID"
            />
          </Col>
          <Col xs={24} sm={8} md={6} lg={4} xl={4} xxl={4}>
            <FancyInput
              onChange={(e) => {
                if (!e) return setFilterVals((prev) => ({ ...prev, repairType: '' }));
                setFilterVals((prev) => ({ ...prev, repairType: e.target.value }));
              }}
              placeholder="Repair Type"
            />
          </Col>
          <Col xs={24} sm={8} md={6} lg={4} xl={4} xxl={4}>
            <FancyInput
              onChange={(e) => {
                if (!e) return setFilterVals((prev) => ({ ...prev, repairLab: '' }));
                setFilterVals((prev) => ({ ...prev, repairLab: e.target.value }));
              }}
              placeholder="Repair Lab"
            />
          </Col>
        </Row>
        <Table
          loading={{ indicator: <LoaderWithMessage loadingMessage="Loading..." />, spinning: loading }}
          columns={columns}
          scroll={{ x: 'max-content' }}
          size={xs ? 'large' : 'small'}
          dataSource={filteredData ?? []}
          rowKey={(record): string => record.repairOrderId}
          pagination={{ pageSize: 25 }}
        />
      </Spin>
    </Space>
  );
};
