import { getResolutionCodes } from 'api/GetResolutionCodes';
import React, { FC, useEffect, useState } from 'react';
import { useAppSelector } from 'redux/store';
import { FieldInputSelect } from './FieldInputSelect';

type Props = {
  isStage?: boolean;
  setForm?: React.Dispatch<
    React.SetStateAction<{
      repairStageId: string;
      technicianId: string;
    }>
  >;
};

const ResolutionCodeSelectComponent: FC<Props> = ({ isStage = false, setForm }): JSX.Element => {
  const [data, setData] = useState<{ resolutionCode: string; resolutionCodeDescription: string }[]>([]);
  const { lookupTables, selectedDataAreaId } = useAppSelector((state) => state).app;

  useEffect(() => {
    const fetchData = async () => {
      const test123 = await getResolutionCodes(selectedDataAreaId);

      const formattedData = test123?.map((item) => {
        return { resolutionCode: item?.resolutionCode, resolutionCodeDescription: item?.resolutionCodeDescription };
      });

      setData(formattedData);

      // const formattedData = test123?.map((item) => item?.['b:RepairStageId']);

      // setData(formattedData);
    };

    fetchData();
  }, [lookupTables]);

  return (
    <FieldInputSelect
      fieldName="resolutionCode"
      label="Resolution Code"
      options={data?.map((item) => ({ label: `${item.resolutionCode} - ${item.resolutionCodeDescription}`, value: item.resolutionCode }))}
      placeholder="Resolution Code"
    />
  );
};

export const ResolutionCodeSelect = React.memo(ResolutionCodeSelectComponent);
